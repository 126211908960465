<template>
    <div class="wayfinder" ref="wayfinder" :style="[element.styles]">
        <template v-if="!this.destination">
            <div class="container-way" v-for="index in computedIterations.numIterations" :key="index"
                :style="{ background: currentTheme.primary }">
                <div class="container-icon">
                    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#wvnqcqbdia)" stroke="#fff" stroke-width="2.254" stroke-linecap="round">
                            <path
                                d="M48.069 44.158h-5.98a1.833 1.833 0 0 1-1.828-1.827V33.61a1.833 1.833 0 0 0-1.827-1.828h-8.662a1.833 1.833 0 0 1-1.827-1.827v-8.639a1.833 1.833 0 0 0-1.827-1.827h-8.662a1.833 1.833 0 0 1-1.827-1.828V9.024A1.833 1.833 0 0 0 13.8 7.195H3.815"
                                stroke-miterlimit="10" />
                            <path d="M26.218 34.395v9.882h-9.881M11.609 29.667l14.504 14.505" stroke-linejoin="round" />
                        </g>
                        <defs>
                            <clipPath id="wvnqcqbdia">
                                <path fill="#fff" transform="translate(2.901 6.282)" d="M0 0h46.081v38.909H0z" />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                <div class="container-text" :style="{ color: currentTheme.txt_primary }">
                    <span>Dubai Room Shangai Room</span>
                    <span>XX Curso de Certificação em ondas de choque</span>
                    <span class="component"
                        :style="{ color: currentTheme.txt_secondary, background: currentTheme.secondary }">
                        Ongoing
                    </span>
                </div>

            </div>
        </template>
        <template v-else>
            <div class="empty-wayfinder" v-if="!this.items.length && this.ready" :style="{ background: currentTheme.primary, color: currentTheme.txt_primary }">
                {{ $t('widgets.noEvents', lang) }}
            </div>
            <template v-else>
                <div class="container-way" v-for="item in currentItems" :key="item.id" :style="{ background: currentTheme.primary }">
                    <div class="container-icon">
                        <img v-if="item.wayfinder_icon"
                            :src="item.wayfinder_icon_url" />
                    </div>
                    <div class="container-text" :style="{ color: currentTheme.txt_primary }">
                        <span>{{ item.destination_location_name }}</span>
                        <span>{{ item.name }}</span>
                        <span
                            :key="refreshKey"
                            class="component"
                            :style="{ color: currentTheme.txt_secondary, background: currentTheme.secondary }">
                            {{ !this.$dayjs(this.$dayjs().tz(this.timezone).format('YYYY-MM-DD HH:mm:ss')).isAfter(item.start_time) ? 
                            `${this.$dayjs(item.start_time).locale(this.lang).calendar(this.$dayjs().tz(this.timezone), {
                                sameDay: `[${this.$t('widgets.today', lang)}] 🞄 LT`, // The same day ( Today at 2:30 AM )
                                nextDay: `[${this.$t('widgets.nextDay', lang)}] 🞄 LT`, // The next day ( Tomorrow at 2:30 AM )
                                nextWeek: 'dddd 🞄 LT', // The next week ( Sunday at 2:30 AM )
                                sameElse: 'LL 🞄 LT' // Everything else ( 7/10/2011 )
                            })}` 
                            : $t('event.ongoing', lang) }}
                          
                        </span>
                    </div>
                </div>
                <div v-for="index in remainingIterations" :key="index" class="container-way remaining" :style="{ background: currentTheme.primary }"></div>
            </template>
        </template>
    </div>
</template>
<script>
import { composerStore, useWayfinderStore } from "@/store";

export default {
    name: "WayfinderComponent",
    props: ["element", "styles", "index", "preview", "stb", "wayfinder", "timezone", "language"],

    setup() {
        const elementsStore = composerStore();
        const wayfinderStore = useWayfinderStore();
        return { elementsStore, wayfinderStore };
    },

    data() {
        return {
            destination: null,
            wayitems: [],
            items: [],
            ready: false,
            currentIndex:  0,

            refreshKey: false,

            transitionInterval: null,
            eventInterval: null,
            newDayInterval: null,

            lang: this.language,
        };
    },
    mounted() {
        this.getItems();
    },
    beforeUnmount() {
        this.stopInterval();
    },
    watch: {
        "element.wayfinder"() {
            this.getItems();
        },
        "element.settings.type"() {
            this.getItems();
        },
        "element.settings.time"() {
            this.getItems();
        },
    },
    methods: {
        setcurrentIndex(index){
            this.currentIndex = index;
        },
        startInterval() {
            this.eventInterval = setInterval(() => {
                if(this.changeRows())
                    this.refreshKey = !this.refreshKey;
            },  1000 )
            
            this.transitionInterval = setInterval(() => {
                this.currentIndex = (this.currentIndex + 1) % Math.ceil(this.items.length / this.computedIterations.numIterations);
            },  this.element.settings.next * 1000);


            // Make Interval for getting new conference events (by today or hours), default 1h
            let intervalTime = 1000 * 60 * 60 * 24;
            if (this.element.settings.type === 'hours') {
                if(this.element.settings.time){
                    const currentDate = this.$dayjs(this.$dayjs().tz(this.timezone).format('YYYY-MM-DD HH:mm:ss'));
                    const nextHour = currentDate.add(1, 'hour').startOf('hour');
                    const timeUntilNextHour = Math.abs(nextHour.diff(currentDate)); 
                    intervalTime = timeUntilNextHour

                }
            } else {
                const currentDate = this.$dayjs(this.$dayjs().tz(this.timezone).format('YYYY-MM-DD HH:mm:ss'));
                const nextHour = currentDate.add(1, 'day').startOf('day');
                const timeUntilNextHour = Math.abs(nextHour.diff(currentDate)); 
                intervalTime = timeUntilNextHour
            }

            this.newDayInterval = setTimeout(() => {
                this.getItems();
            }, intervalTime);
        },
        stopInterval() {
            clearInterval(this.transitionInterval);
            clearInterval(this.eventInterval);
            clearTimeout(this.newDayInterval)
        },
        changeRows(){
            this.items.forEach((item) => {
                if(this.$dayjs(this.$dayjs().tz(this.timezone).format('YYYY-MM-DD HH:mm:ss')).isAfter(item.end_time)){
                    this.items = this.items.filter((i) => i !== item)
                    return true;
                }
            })
            return false;
        },
        async getItems() {
            this.stopInterval();
            this.ready = false;
            this.currentIndex = 0;
            this.items = [];
            let result = [];
            this.destination = this.stb ? this.wayfinder : this.element.wayfinder;
            if (!this.stb && !this.element.wayfinder) return;

            try {
                if (this.element.settings.type === 'hours') {
                const hours = this.element.settings.time;
                result = await this.wayfinderStore.wayfinderDestinations(this.destination, hours);
            } else {
                result = await this.wayfinderStore.wayfinderDestinations(this.destination);
            }
            } catch (error) {
                this.result = [];
            }
            
            // Extract all conferences with their associated information
            const items = JSON.parse(JSON.stringify(result)).flatMap(item => {
                return item.conferences.map(conference => {                  
                    return {
                        destination_location: item.destination_location,
                        destination_location_name: item.destination_location_name,
                        wayfinder_icon: item.wayfinder_icon,
                        wayfinder_icon_url: item.wayfinder_icon_url,
                        ...conference
                    };
                });
            });

            this.items = items;
            // Sort the items by start_time
            this.items.sort((a, b) => new Date(a.start_time) - new Date(b.start_time));
            this.ready = true;
            if(!this.preview) return;
            this.changeRows();
            this.startInterval();
            this.refreshKey = true;
        },
    },
    computed: {
        remainingIterations() {
            // Calculate the number of empty containers needed
            return Math.max(0, this.computedIterations.numIterations - this.currentItems.length);
        },
        currentItems() {
            const start = this.currentIndex * this.computedIterations.numIterations;
            let end = start + this.computedIterations.numIterations;
            let items = this.items;

            // If start exceeds the total number of items, adjust currentIndex
            if (start >= this.items.length && this.items.length > 0) {
                this.setcurrentIndex(0);
                end = Math.min(this.computedIterations.numIterations, this.items.length);
            }
            // If end exceeds the total number of items, adjust it
            if (end > this.items.length) {
                end = this.items.length;
            }

            return items.slice(start, end);
        },
        currentTheme() {
            const theme = this.element.theme;
            return theme;
        },
        computedIterations() {
            const containerHeight = this.element.height;
            const initialHeight = 214; // Height at which it starts with 1 item
            const heightIncrement = 216; // Adjust this value as needed

            // Calculate the number of iterations
            let numIterations = 1;
            if (containerHeight > initialHeight) {
                numIterations += Math.floor((containerHeight - initialHeight) / heightIncrement);
            }
            return { numIterations };
        },
    }
};
</script>
<style scoped lang="scss">
.wayfinder {
    width: 100%;
    height: 100%;
    color: white;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.container-way {
    min-height: 214px;
    height: 100%;
    display: flex;
    gap: 25px;
    padding-inline: 30px;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background-color: #253444;
    overflow: hidden;
    margin-bottom: 2px;

    &.remaining {
        // FIX FOR CHROME ZOOM - 216.5px;
        margin-bottom: -1.3px;
        min-height: 214px; 
        height: 100%;
    }
}

.empty-wayfinder{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    font-weight: 600;
    line-height: 1.13;
    letter-spacing: 0.5px;
}

.container-icon {
    min-width: 164.3px;
    max-width: 164.3px;
    height: 164.3px;
    padding: 30px;
    display: flex;
    place-items: center;
    place-content: center;

    & svg {
        width: 104.1px;
        height: 104.1px;
    }

    & img {
        width: 100%;
        height: 100%;
    }
}

.container-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-stretch: normal;
    font-style: normal;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    & span {
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    & span:nth-child(1) {
        font-size: 45.2px;
        font-weight: 600;
        line-height: 1.23;
    }

    & span:nth-child(2) {
        font-size: 28.7px;
        font-weight: normal;
        line-height: 1.14;
    }

}

.component {
    font-size: 28.7px;
    line-height: 1.29;
    letter-spacing: 1.03px;
    text-align: center;
    margin-top: 15px;
    padding: 0px 15px;
    border-radius: 10.3px;
    background-color: #00af8b;
    width: unset !important;
}</style>